import Web3 from "web3";
import { ERC1155 } from "multi-token-standard-abi";
import { ERC721ABI } from "../contract/ERC721";
import saveTransaction from "../apis/multeez/saveTransaction";
import { TransactionOriginAction } from "../hooks/useCheckIfTransactionSent";
import { Proposal } from "../types/common";

const ERC721NameConvention = "ERC721";

export const isMetaMaskInstalled = () => {
  //Have to check the ethereum binding on the window object to see if it's installed
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};

export type TransferNftArgs = {
  contractAddress: string;
  tokenId: string;
  to: string;
  nftType: string;
  proposalData: Proposal;
  onComplete: () => void;
};

export const transferNft = async (args: TransferNftArgs) => {
  const { ethereum, web3: metamask } = window;
  // connect to metamask (will ask user to connect if he is not already) and take the first account
  const accounts = await ethereum.request({ method: "eth_requestAccounts" });
  // transfer funds from the metamask wallet to the local magic defined wallet
  // Get the contract instance using your contract's abi and address:
  const metamaskWeb3 = new Web3(metamask.currentProvider);

  if (args.nftType === ERC721NameConvention) {
    const contractInstance = new metamaskWeb3.eth.Contract(
      ERC721ABI.abi,
      args.contractAddress
    );
    await contractInstance.methods
      .safeTransferFrom(accounts[0], args.to, args.tokenId)
      .send({ from: accounts[0] })
      .on("transactionHash", (transactionHash) => {
        saveTransaction({
          transactionHash,
          proposal: args.proposalData,
          userId: args.proposalData.proposer,
          originAction: TransactionOriginAction.Byon,
          onComplete: args.onComplete,
        });
      });
  } else {
    const contractInstance = new metamaskWeb3.eth.Contract(
      ERC1155.abi,
      args.contractAddress
    );
    await contractInstance.methods
      .safeTransferFrom(accounts[0], args.to, args.tokenId, 1, 0)
      .send({ from: accounts[0] })
      .on("transactionHash", (transactionHash) => {
        saveTransaction({
          transactionHash,
          proposal: args.proposalData,
          userId: args.proposalData.proposer,
          originAction: TransactionOriginAction.Byon,
          onComplete: args.onComplete,
        });
      });
  }
};
