import { TextField, styled } from "@mui/material";
import { ReactComponent as Close } from "assets/close.svg";
import { ReactComponent as Arrow } from "assets/arrow-down.svg";

export const Input = styled(TextField, {
  shouldForwardProp: (prop) => prop !== "touched",
})<{ touched: boolean }>`
  & .MuiInput-root {
    height: 45px;
  }
  & .MuiInput-input {
    font-size: 36px;
    font-weight: 300;
    text-align: center;
    color: #adcccc;
    color: ${({ touched }) => (touched ? "#000000" : "#adcccc")};
    margin: 0;
    padding: 0;
  }
  & .MuiInputAdornment-root {
    align-items: flex-start;
  }
  & .MuiInputAdornment-root > p {
    color: ${({ touched }) => (touched ? "#000000" : "#adcccc")};
    font-size: 12px;
  }
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
`;

export const ArrowIcon = styled(Arrow)`
  height: 15px;
  width: 15px;
  margin-right: 8px;
  & > path {
    fill: ${({ theme }) => theme.palette.primary.main};
  }
`;
