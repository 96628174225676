import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
  useMemo,
  ReactNode,
} from "react";
import { useNavigate } from "react-router-dom";
import { MagicUserMetadata } from "magic-sdk";
import LogRocket from "logrocket";
import { magicEthereum } from "../magic";
import { postAuthRequest, setToken, invalidateToken } from "../utils/auth";
import useUserData from "../hooks/useUserData";

const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

type AuthContextType = {
  magicUser: MagicUserMetadata | null;
  isLoggedIn: boolean;
  isLoading: boolean;
  signin: (
    phoneNumber: string,
    email: string | null,
    callback: VoidFunction
  ) => void;
  signout: () => void;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [magicUser, setMagicUser] = useState<MagicUserMetadata | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { data: user } = useUserData();

  async function checkLogin() {
    const loggedInState = await magicEthereum.user.isLoggedIn();
    setIsLoggedIn(loggedInState);

    if (loggedInState) {
      const user = await magicEthereum.user.getMetadata();
      setMagicUser(user);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    checkLogin();
  }, []);

  useEffect(() => {
    if (!user) return;

    if (magicUser) {
      magicUser.email = user.email;
    }

    LogRocket.identify(user?.email || "", {
      publicAddress: magicUser?.publicAddress || "",
    });
  }, [user, magicUser]);

  const signin = useCallback(
    async (
      phoneNumber: string,
      email: string | null,
      callback: VoidFunction
    ) => {
      const did = await magicEthereum.auth.loginWithSMS({
        phoneNumber: `+${phoneNumber}`,
      });
      setToken(did);
      await checkLogin();
      const params: { phone: string; email?: string } = {
        phone: `+${phoneNumber}`,
      };

      if (email) {
        params.email = email;
      }

      const url = `${baseUrl}/api/v0/signup`;
      await postAuthRequest(url, params);
      callback();
    },
    []
  );

  const signout = useCallback(async () => {
    localStorage.clear();
    invalidateToken();
    await magicEthereum.user.logout();
    setMagicUser(null);
    setIsLoggedIn(false);
    navigate("/login");
  }, [navigate]);

  const memoedValue = useMemo(
    () => ({
      isLoading,
      isLoggedIn,
      magicUser,
      signin,
      signout,
    }),
    [magicUser, isLoggedIn, isLoading, signin, signout]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export default useAuth;
