import { useQuery } from "react-query";
import axios from "axios";
import { Proposal } from "../types/common";
import { getAuthHeader } from "../utils/auth";

const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

const fetchProposalsData = async (): Promise<Proposal[] | []> => {
  const { data } = await axios.get(`${baseUrl}/api/v0/userProposals`, {
    headers: await getAuthHeader(),
  });

  return data.sort((a, b) => b.proposalTime - a.proposalTime);
};

export default function () {
  return useQuery(["proposalsData"], fetchProposalsData);
}
