import { Stack, styled } from "@mui/material";
import bgSrc from "assets/bg.svg";

const Container = styled(Stack)(({ theme }) => ({
  backgroundImage: `url(${bgSrc})`,
  backgroundColor: "#ffffff",
  boxShadow: "0 -13px 20px rgba(95, 81, 236, 0.06)",
  padding: theme.spacing(3, 3, 3),
  borderTopLeftRadius: 24,
  borderTopRightRadius: 24,
  position: "absolute",
  top: 20,
  left: 0,
  right: 0,
  margin: "auto",
  maxWidth: 500,
  height: "100%",
  width: "100%",
}));

const BottomDrawer = ({ children }) => <Container>{children}</Container>;

export default BottomDrawer;
