import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";

type Props = {
  data: string[][];
};

const TransactionRecap: FC<Props> = ({ data }) => {
  return (
    <Stack mb={5}>
      {data.map(([title, value]) => (
        <Box key={title} display="flex" justifyContent="space-between">
          <Typography component="span" variant="subtitle2">
            {title}
          </Typography>
          <Typography component="span" variant="subtitle2">
            {value}
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};

export default TransactionRecap;
