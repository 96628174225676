import { Typography } from "@mui/material";

const Disclaimer = () => {
  return (
    <Typography variant="subtitle2" align="center" mt={7}>
      *This includes the DAO fee and transaction fees. In case of extreme gas
      fluctuations, you will be requested to add sufficient funds.
    </Typography>
  );
};

export default Disclaimer;
