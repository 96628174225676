import { useState, useEffect } from "react";
import { BigNumber } from "bignumber.js";
import Swal from "sweetalert2";
import { Stack, Typography } from "@mui/material";
import { ProposalVoter } from "../../types/common";
import { executeBuyout, getUserVoterData } from "./utils";
import { getAuthRequest } from "utils/auth";
import { toFixed } from "utils/common";
import { ethWeb3 } from "magic";
import {
  calcMissingFundsForActionWei,
  createContractInstance,
  isDaoHoldsThisNft,
  METHOD_GAS_LIMIT,
  runWithRetries,
  MULTEEZ_FEE_PRCT,
} from "utils";
import AddGasModal from "components/AddGasModal";
import Page from "components/Page";
import Button from "components/Button";
import Loading from "components/Loading";
import { logError, logInfo } from "utils/logger";
import useUserData from "hooks/useUserData";
import useProposalData from "hooks/useProposalData";
import useCheckIfTransactionSent, {
  TransactionOriginAction,
} from "hooks/useCheckIfTransactionSent";
import useCurrentGasPrice from "hooks/useCurrentGasPrice";
import NftInfoCard from "components/NftInfoCard";
import Disclaimer from "components/Disclaimer";
import TransactionRecap from "components/TransactionRecap";

const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

export default function BuyOut() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const gasUnitPrice = useCurrentGasPrice();
  const { data: userData } = useUserData();
  const { data: proposalData } = useProposalData(
    urlParams.get("proposalId") || ""
  );

  const [disableButton, setDisableButton] = useState(false);
  const [showAddGasModal, setShowAddGasModal] = useState(false);
  const [gasNeeded, setGasNeeded] = useState("0");
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [buyerNewSeats, setBuyerNewSeats] = useState(0);
  const [buyerData, setBuyerData] = useState<ProposalVoter>();
  const [totalPrice, setTotalPrice] = useState(0);
  const [numberOfSlots, setNumberOfSlots] = useState(0);

  useEffect(() => {
    if (!proposalData || !userData) {
      return;
    }

    setDisableButton(true);
    const userBuyerData = getUserVoterData(proposalData.voters, userData.id);

    if (!userBuyerData) {
      return;
    }

    setBuyerData(userBuyerData);
    const buyerSlots = userBuyerData.slots ? userBuyerData.slots : 0;
    setBuyerNewSeats(buyerSlots);
    setTotalPrice(
      new BigNumber(buyerSlots)
        .multipliedBy(proposalData.proposedValue)
        .toNumber()
    );
    setNumberOfSlots(proposalData.slots ? proposalData.slots : 0);
    setDisableButton(false);
  }, [proposalData, userData]);

  useCheckIfTransactionSent({
    user: userData,
    proposal: proposalData,
    originAction: TransactionOriginAction.Buyout,
  });

  const shouldDisable = () => !proposalData || !userData;

  const handleConfirm = async () => {
    if (!proposalData || !userData) {
      return;
    }

    try {
      setIsActionInProgress(true);
      setDisableButton(true);
      const daoAddress = proposalData?.daoAddress;
      const nftAddress = proposalData?.tokenAddress || "";
      const nftId = proposalData?.tokenID || "";

      const accounts = await ethWeb3.eth.getAccounts();
      const userAddress = accounts[0];

      if (!buyerData) {
        // TODO: checking if his transaction is closed
        logError("user bid did not found");
        return "";
      }

      const buyoutPriceEther = buyerData?.contribution;
      const userShare = buyerData?.userShare || 0;
      const buyoutPrice = ethWeb3.utils.toWei(String(buyoutPriceEther));
      const buyoutPriceBN = new BigNumber(buyoutPrice);
      const howMuchGasNeeded = await calcMissingFundsForActionWei({
        gasUnitPrice,
        gasUnits: METHOD_GAS_LIMIT,
        user: userData,
        additionalFeesInWei: new BigNumber(buyoutPrice),
      });

      if (!howMuchGasNeeded.isEqualTo("0")) {
        setGasNeeded(howMuchGasNeeded.toString());
        await logInfo(
          `to low balance for request with additional needed is ${howMuchGasNeeded.toString()}`
        );
        setShowAddGasModal(true);
        return;
      }

      const daoDecimals = 1000;
      const buyoutPercent = new BigNumber(userShare)
        .multipliedBy(daoDecimals)
        .toNumber();
      const sellerMail = urlParams.get("sellerMail");

      const endpoint = `${baseUrl}/api/v0/userbymail/${sellerMail}`;
      let magicAddressSeller = "";
      const res = await getAuthRequest(endpoint);

      if (res.status === 200) {
        magicAddressSeller = res.data.magicWallet;
      }

      const dao = await runWithRetries(createContractInstance, daoAddress);

      if (!dao) {
        await logInfo(`DAO instance failed to be created please ${daoAddress}`);
        alert(
          "DAO failed to be created please try again later no Eth was used"
        );
        return "";
      }

      const buyoutDecimal = 0;

      try {
        await logInfo("checking is dao holds nft:");
        const _isDaoHoldsNFT = await isDaoHoldsThisNft(
          nftAddress,
          nftId,
          daoAddress || ""
        );

        if (!_isDaoHoldsNFT) {
          Swal.fire({
            title: "Transaction failed",
            text: "The specified DAO is not an owner of this NFT",
            icon: "error",
            showConfirmButton: false,
            timer: 3000,
          });
          await logInfo("Dao is not holding the proposal nft");
          return;
        }
      } catch (eHolding) {
        await logError(
          "is dao holds nft check crashed (most likely due to lazy nft and therefor dao is not holding it) with error"
        );
        await logError(eHolding);
        Swal.fire({
          title: "Failed",
          text: "No money was transferred",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      const sellerUserId = `USER_${sellerMail}`;
      logInfo(
        `executing buy out with ${buyoutPriceBN.toString()} price , ${buyoutPercent}% and proposal id ${
          proposalData.id
        } `
      );
      await executeBuyout({
        userAddress: userAddress,
        sellerAddress: magicAddressSeller,
        buyoutPrice: buyoutPriceBN.toString(),
        buyoutPercent,
        buyoutDecimal,
        dao,
        gasUnitPrice,
        proposal: proposalData,
        buyerUserId: userData.id,
        sellerUserId,
        onComplete: () => setIsActionInProgress(false),
      });
    } catch (e) {
      Swal.fire({
        title: "Failed",
        text: "Couldn't create Buyout request",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
      logError();
      logError(e);
    }
  };

  return (
    <Page>
      {(!userData || isActionInProgress) && (
        <Loading text={"Loading, please wait..."} />
      )}
      {showAddGasModal && (
        <AddGasModal
          open={showAddGasModal}
          onClose={() => setShowAddGasModal(false)}
          gasPriceInWei={gasNeeded.toString()}
          onFundsAdded={confirm}
          userEmail={userData?.email || ""}
        />
      )}
      <Stack>
        <Typography variant="h5" fontWeight={900} my={4}>
          Purchase confirmation
        </Typography>
        <NftInfoCard
          imageUrl={proposalData?.imageUrl}
          name={proposalData?.nftName}
          type="buyout"
          proposedSeats={buyerNewSeats}
          proposedValue={totalPrice}
          headerInfo={[["Total number of seats", `${numberOfSlots}`]]}
        />
        <Typography variant="subtitle2" fontWeight={900} mt={6} mb={2}>
          Transaction Recap
        </Typography>
        <TransactionRecap
          data={[
            ["Price", `${totalPrice} ETH`],
            [
              "Estimated fees*",
              `${toFixed(
                new BigNumber(
                  ethWeb3.utils.fromWei(gasUnitPrice.toString())
                ).multipliedBy(String(METHOD_GAS_LIMIT))
              )} ETH`,
            ],
            ["Multeez service fee", "Free"],
          ]}
        />
        <Button
          disabled={disableButton || shouldDisable()}
          onClick={handleConfirm}
        >
          <Typography variant="h6" fontWeight={900} component="span">
            Confirm
          </Typography>
        </Button>
        <Disclaimer />
      </Stack>
    </Page>
  );
}
