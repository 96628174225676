import { styled } from "@mui/material/styles";
import { LoadingButton as Button } from "@mui/lab";

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) =>
    prop !== "backgroundColor" &&
    prop !== "color" &&
    prop !== "boxShadow" &&
    prop !== "width" &&
    prop !== "isSmall",
})(({ isSmall, backgroundColor, color, boxShadow, width }) => ({
  textTransform: "none",
  fontSize: 18,
  backgroundColor,
  color: color,
  boxShadow,
  borderRadius: 12,
  height: isSmall ? 38 : 60,
  width: isSmall ? 96 : width,
  "&:hover": {
    backgroundColor,
  },
  "&:active": {
    backgroundColor,
  },
}));

const CustomButton = ({
  children,
  isSmall = false,
  color = "",
  boxShadow = "",
  backgroundColor = "#2E3A59",
  width = "100%",
  ...props
}) => {
  return (
    <StyledButton
      isSmall={isSmall}
      backgroundColor={backgroundColor}
      color={color}
      width={width}
      boxShadow={boxShadow}
      fullWidth={!isSmall}
      size={isSmall ? "small" : "large"}
      variant="contained"
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;
