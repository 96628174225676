import { Card, styled } from "@mui/material";

export const NFTCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  padding: 24,
  borderRadius: 20,
  boxShadow:
    "0px 8px 16px rgba(95, 81, 236, 0.16), 0px 2px 4px rgba(95, 81, 236, 0.04)",
});

export const NFTImage = styled("img")(({ theme }) => ({
  objectFit: "cover",
  width: 91,
  height: 104,
  borderRadius: 3,
  marginRight: theme.spacing(2),
}));
