import { useCallback, useState, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import useDeployDaoOrAddFunds from "./hooks/useDeployDaoOrAddFunds";
import useBuyNft from "./hooks/useBuyNft";
import { useUserProposalInfo } from "hooks/useUserProposalInfo";
import { TransactionStatus } from "types/common";
import useProposalData from "hooks/useProposalData";
import Page from "components/Page";
import Button from "components/Button";
import Loading from "components/Loading";
import AddGasModal from "components/AddGasModal";
import useUserData from "hooks/useUserData";
import useCheckIfTransactionSent, {
  TransactionOriginAction,
} from "hooks/useCheckIfTransactionSent";
import useCurrentGasPrice from "hooks/useCurrentGasPrice";
import useQueryParams from "hooks/useQueryParams";
import NftInfoCard from "components/NftInfoCard";
import Disclaimer from "components/Disclaimer";
import { ownershipPrct } from "utils/common";
import TransactionRecap from "components/TransactionRecap";

export default function Deploy() {
  const urlParams = useQueryParams();
  const gasUnitPrice = useCurrentGasPrice();
  const { data: userData } = useUserData();
  const { data: proposalData } = useProposalData(
    urlParams.get("proposalId") || ""
  );

  const [disableButton, setDisableButton] = useState(false);
  const [showAddGasModal, setShowAddGasModal] = useState(false);

  const proposalInfo = useUserProposalInfo({
    proposal: proposalData,
    setDisplayedBalance: () => {
      return "";
    },
    user: userData,
  });

  const { userBid, userFees, userSlots } = proposalInfo;

  const { missingFunds, isDeployInProgress, deployDaoOrAddFunds } =
    useDeployDaoOrAddFunds({
      gasUnitPrice,
      proposal: proposalData,
      setShowAddGasModal,
    });

  const { buyNft, buyNftLoading } = useBuyNft({
    proposal: proposalData,
    user: userData,
  });

  const addedFundsToDao = useCheckIfTransactionSent({
    user: userData,
    proposal: proposalData,
    originAction: TransactionOriginAction.AddFundsToDao,
    hideAlert: userData && proposalData?.isDirectBuy,
  });

  const purchasedNft = useCheckIfTransactionSent({
    user: userData,
    proposal: proposalData,
    originAction: TransactionOriginAction.DirectBuy,
  });

  const shouldDisableButton = () =>
    !proposalData ||
    !userData ||
    disableButton ||
    purchasedNft.status === TransactionStatus.Success;

  const onConfirm = useCallback(async () => {
    setDisableButton(true);

    if (
      addedFundsToDao.status === TransactionStatus.Success &&
      proposalData?.daoAddress !== "-" &&
      proposalData?.isDirectBuy
    ) {
      await buyNft();
    } else {
      await deployDaoOrAddFunds();
    }

    setDisableButton(false);
  }, [addedFundsToDao, buyNft, deployDaoOrAddFunds, proposalData]);

  const buttonText = useMemo(() => {
    if (proposalData?.daoAddress === "-") {
      return "Deploy DAO";
    }

    if (addedFundsToDao.status !== TransactionStatus.Success) {
      return "Confirm";
    }

    if (proposalData?.isDirectBuy) {
      return "Purchase";
    }

    return "Done";
  }, [proposalData, userData, addedFundsToDao]);

  return (
    <Page>
      {(!userData || !proposalData || isDeployInProgress || buyNftLoading) && (
        <Loading text="Loading, please wait..." />
      )}
      {showAddGasModal && (
        <AddGasModal
          open={showAddGasModal}
          onClose={() => setShowAddGasModal(false)}
          gasPriceInWei={missingFunds}
          onFundsAdded={deployDaoOrAddFunds}
          userEmail={userData?.email || ""}
        />
      )}
      <Stack>
        <Typography variant="h5" fontWeight={900} my={4}>
          Confirmation
        </Typography>
        <NftInfoCard
          imageUrl={proposalData?.imageUrl}
          name={proposalData?.nftName}
          owners={proposalData?.voters.length}
          headerInfo={[
            [
              "Ownership",
              `${userSlots} seats out of total ${proposalData?.slots} seats`,
            ],
            ["Total NFT price", `${proposalData?.proposedValue} ETH`],
          ]}
        />
        <Typography variant="subtitle2" fontWeight={900} mt={4} mb={2}>
          Transaction Recap
        </Typography>
        <TransactionRecap
          data={[
            ["Your contribution", `${userBid} ETH`],
            ["Estimated fee*", `${userFees} ETH`],
            ["Multeez service fee", "Free"],
          ]}
        />
        <Button disabled={shouldDisableButton()} onClick={onConfirm}>
          <Typography variant="h6" fontWeight={900} component="span">
            {buttonText}
          </Typography>
        </Button>
        <Disclaimer />
      </Stack>
    </Page>
  );
}
