import { CircularProgress, Stack, Typography } from "@mui/material";
import ActivityItem from "./ActivityItem/index";
import useProposalsData from "hooks/useProposalsData";
import { Proposal } from "types/common";

const Activity = ({ userData }) => {
  const { data: proposals, isLoading } = useProposalsData();

  return (
    <Stack pt={1} pb={2}>
      {isLoading ? (
        <CircularProgress size={50} />
      ) : proposals?.length ? (
        proposals.map((proposal: Proposal) => (
          <ActivityItem
            key={proposal.id}
            userId={userData?.id}
            proposal={proposal}
          />
        ))
      ) : (
        <Typography variant="h5" align="center">
          No data
        </Typography>
      )}
    </Stack>
  );
};

export default Activity;
