import { useCallback, useState } from "react";
import { Outlet, useOutletContext, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ClipboardJS from "clipboard";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import * as S from "./Wallet.styles";
import Activity from "./components/Activity";
import NftsList from "./components/NftsList";
import useUserData from "hooks/useUserData";
import useUserFreeBalance from "hooks/useUserFreeBalance";
import { addEllipses } from "utils/common";
import Page from "components/Page";
import TabPanel from "components/TabPanel";
import { ReactComponent as CopyIcon } from "assets/copy.svg";
import { ReactComponent as DepositIcon } from "assets/arrow-down.svg";
import { ReactComponent as TransferIcon } from "assets/transfer-arrows.svg";
import { User } from "types/common";

new ClipboardJS(".copy-address-btn");

type ContextType = {
  userData: User | null;
  userFreeBalance: string;
};

export function useUser() {
  return useOutletContext<ContextType>();
}

const Wallet = () => {
  const navigate = useNavigate();
  const { data: userData } = useUserData();
  const { data: userFreeBalance } = useUserFreeBalance(
    userData?.email,
    userData?.magicWallet
  );
  const [tabValue, setTabValue] = useState(0);
  const [nftsCount, setNftsCount] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const showFeedbackOnCopy = useCallback(() => {
    Swal.fire({
      title: "Address copied!",
      icon: "success",
      showConfirmButton: false,
      timer: 1000,
    });
  }, []);

  return (
    <Page>
      <Helmet>
        <script
          src={`https://verify.${
            process.env.REACT_APP_WYRE_ENVIRONMENT === "test" ? "test" : "send"
          }wyre.com/js/verify-module-init-beta.js`}
        />
      </Helmet>
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          mt={3}
        >
          <Box
            sx={{
              position: "absolute",
              left: -9999,
              opacity: 0,
              height: 0,
              width: 0,
            }}
            id="public-address"
          >
            {userData?.magicWallet}
          </Box>
          <Typography variant="body2" mr={1}>
            {addEllipses(userData?.magicWallet as string, 12)}
          </Typography>
          <CopyIcon
            className="copy-address-btn"
            data-clipboard-target="#public-address"
            style={{ cursor: "pointer" }}
            onClick={showFeedbackOnCopy}
          />
        </Stack>
        <Typography variant="h3" my={3} align="center">
          {!userFreeBalance ? (
            <CircularProgress size={32} />
          ) : (
            `${userFreeBalance?.toFixed(3)} ETH`
          )}
        </Typography>
        <Stack direction="row" justifyContent="center" mb={3}>
          <S.DepositButton
            onClick={() => navigate("deposit")}
            startIcon={<DepositIcon />}
          >
            Deposit
          </S.DepositButton>
          <S.TransferButton
            onClick={() => navigate("transfer")}
            startIcon={<TransferIcon />}
          >
            Transfer
          </S.TransferButton>
        </Stack>
        <S.Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange}>
          <S.Tab label={`Showcase (${nftsCount})`} />
          <S.Tab label="Activity" />
        </S.Tabs>
        <TabPanel value={tabValue} index={0}>
          <NftsList setCount={setNftsCount} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <Activity userData={userData} />
        </TabPanel>
        <Outlet
          context={{
            userData,
            userFreeBalance,
          }}
        />
      </Stack>
    </Page>
  );
};

export default Wallet;
