import { useEffect } from "react";
import { CircularProgress, Stack } from "@mui/material";
import useUserNftsData from "hooks/useUserNftsData";
import NftCard from "pages/Wallet/components/NftCard";

const NftsList = ({ setCount }) => {
  const { data: userNftsData, isLoading: isUserNftsDataLoading } =
    useUserNftsData();

  useEffect(() => {
    if (userNftsData) setCount(userNftsData.length);
  }, [userNftsData, setCount]);

  return (
    <Stack gap={3} py={2} alignItems="center">
      {isUserNftsDataLoading ? (
        <CircularProgress size={50} />
      ) : (
        userNftsData?.map((nft) => <NftCard key={nft.id} data={nft} />)
      )}
    </Stack>
  );
};

export default NftsList;
