import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import * as S from "./NftInfoCard.styles";

type Props = {
  imageUrl?: string;
  name?: string;
  owners?: number;
  headerInfo: string[][];
  type?: "requestbuyout" | "buyout" | "sell";
  proposedSeats?: number;
  proposedValue?: number;
};

const NftInfoCard: FC<Props> = ({
  imageUrl,
  name,
  owners,
  headerInfo,
  type,
  proposedSeats,
  proposedValue,
}) => {
  return (
    <S.NFTCard>
      <Stack direction="row">
        <S.NFTImage src={imageUrl} alt={name} />
        <Stack>
          <Typography variant="body" fontWeight={900}>
            {name}
          </Typography>
          {Boolean(owners) && (
            <Typography variant="body3" fontWeight={900} color="#4F42CC">
              {owners || 1} Owner
              {owners && owners > 1 ? "s" : ""}
            </Typography>
          )}
          {headerInfo.map(([title, value]) => (
            <Typography key={title} variant="body3">
              {title}: <strong>{value}</strong>
            </Typography>
          ))}
        </Stack>
      </Stack>
      {(type === "requestbuyout" || type === "buyout") && (
        <Typography variant="body" align="center" fontWeight={900} mt={2}>
          {type === "buyout" ? "Buy" : "Sale price:"}{" "}
          <Typography component="span" fontWeight={900} color="#5F51EC">
            {proposedSeats} seats
          </Typography>{" "}
          for{" "}
          <Typography component="span" fontWeight={900} color="#5F51EC">
            {proposedValue} ETH
          </Typography>
        </Typography>
      )}
      {type === "sell" && (
        <Typography variant="body" align="center" fontWeight={900} mt={2}>
          Sale price:{" "}
          <Typography component="span" fontWeight={900} color="#5F51EC">
            {proposedValue} ETH
          </Typography>
        </Typography>
      )}
    </S.NFTCard>
  );
};

export default NftInfoCard;
