import { Box, Stack, Typography } from "@mui/material";
import { getProposalStatus } from "./utils";

const ActivityItem = ({ userId, proposal }) => {
  const status = getProposalStatus(userId, proposal);

  return (
    <Stack
      direction="row"
      alignItems="center"
      borderBottom="1px solid rgba(165, 166, 246, 0.12)"
      gap={2}
      py={2}
    >
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          height: 40,
          width: 40,
          minWidth: 40,
          borderRadius: 5,
          background: `linear-gradient(to right, ${status?.color})`,
        }}
      >
        <Box
          component="img"
          height={36}
          width={36}
          borderRadius={5}
          src={proposal?.imageUrl}
          border="1px solid #fff"
        />
      </Stack>
      <Typography variant="body1">{status?.text}</Typography>
    </Stack>
  );
};

export default ActivityItem;
