import { FC, ReactNode, useCallback } from "react";
import Swal from "sweetalert2";
import { Stack, Box, Typography, useTheme } from "@mui/material";
import Button from "components/Button";
import useAuth from "context/useAuth";
import { ReactComponent as Logo } from "assets/logo.svg";

type Props = {
  children: ReactNode;
  showLogoutBtn?: boolean;
  showLogo?: boolean;
};

const Page: FC<Props> = ({ children, showLogoutBtn, showLogo }) => {
  const theme = useTheme();
  const { signout } = useAuth();

  const logout = useCallback(() => {
    Swal.fire({
      title: "You have successfully logged out. 🙂",
      icon: "success",
      showConfirmButton: false,
      timer: 2000,
    });
    signout();
  }, []);

  return (
    <Stack
      sx={{
        maxWidth: 500,
        height: "100%",
        width: "100%",
        px: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {showLogoutBtn && (
          <Box mb={4}>
            <Button
              isSmall
              onClick={logout}
              backgroundColor="#ffffff"
              color="#000000"
              boxShadow="9px 9px 26px rgba(95, 81, 236, 0.1)"
            >
              <Typography variant="subtitle2">Logout</Typography>
            </Button>
          </Box>
        )}
      </Box>
      {showLogo && (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            mt: 4,
            mb: 3,
          }}
        >
          <Box
            sx={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              backgroundColor: "#ffffff",
            }}
          >
            <Logo width="13px" />
          </Box>
        </Box>
      )}
      {children}
    </Stack>
  );
};

export default Page;
