import { CreateBuyoutRequestParams } from "./types";
import saveTransaction from "apis/multeez/saveTransaction";
import { TransactionOriginAction } from "hooks/useCheckIfTransactionSent";
import { METHOD_GAS_LIMIT } from "utils";

export async function createBuyoutRequest({
  userAddress,
  buyoutPrice,
  buyoutPercent,
  dao,
  gasUnitPrice,
  proposal,
  userId,
  buyerUserId,
  onComplete,
}: CreateBuyoutRequestParams) {
  console.log(buyoutPrice);
  console.log(buyoutPercent);
  const buyoutEpsilon = proposal.buyoutEpsilon ? proposal.buyoutEpsilon : "0";
  return dao.methods
    .requestBuyOut(buyoutPrice, buyoutPercent, buyoutEpsilon)
    .send({ from: userAddress, gas: METHOD_GAS_LIMIT, gasPrice: gasUnitPrice })
    .on("transactionHash", (transactionHash) =>
      saveTransaction({
        proposal,
        userId,
        buyoutSecondUserId: buyerUserId,
        onComplete,
        transactionHash,
        originAction: TransactionOriginAction.RequestBuyout,
      })
    );
}
