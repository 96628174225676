import { styled } from "@mui/material/styles";
import { Button, Tab as MuiTab, Tabs as MuiTabs } from "@mui/material";

export const StyledButton = styled(Button)`
  width: 103px;
  height: 40px;
  border-radius: 20px;
  text-transform: none;
  font-weight: 600;
`;

export const DepositButton = styled(StyledButton)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: #ffffff;
  margin-right: ${({ theme }) => theme.spacing(2)};
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
    opacity: 0.9;
  }
`;

export const TransferButton = styled(StyledButton)`
  background-color: #ffffff;
  border: ${({ theme }) => `2px solid ${theme.palette.primary.main}`};
  color: ${({ theme }) => theme.palette.primary.main};
`;

export const Tabs = styled(MuiTabs)`
  border-bottom: 1px solid rgba(165, 166, 246, 0.12);
  & .MuiTabs-indicator {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const Tab = styled(MuiTab)`
  text-transform: none;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: 600;
  font-size: 1rem;
  opacity: 0.6;
  &.Mui-selected {
    opacity: 1;
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;
