import { ProposalVoter } from "../../types/common";
import { ExecuteBuyoutParams } from "./types";
import {
  checkIfTransactionSent,
  TransactionOriginAction,
  UseCheckIfTransactionSentParams,
} from "hooks/useCheckIfTransactionSent";
import saveTransaction from "apis/multeez/saveTransaction";
import { METHOD_GAS_LIMIT } from "utils";

export async function executeBuyout({
  userAddress,
  sellerAddress,
  buyoutPrice,
  buyoutPercent,
  buyoutDecimal,
  dao,
  gasUnitPrice,
  proposal,
  buyerUserId,
  sellerUserId,
  onComplete,
}: ExecuteBuyoutParams) {
  return dao.methods
    .buyout(sellerAddress, buyoutPercent, buyoutDecimal)
    .send({
      from: userAddress,
      value: buyoutPrice,
      gas: METHOD_GAS_LIMIT,
      gasPrice: gasUnitPrice,
    })
    .on("transactionHash", (transactionHash) =>
      saveTransaction({
        transactionHash,
        proposal,
        userId: buyerUserId,
        originAction: TransactionOriginAction.Buyout,
        onComplete,
        buyoutSecondUserId: sellerUserId,
      })
    );
}

export function getUserVoterData(
  voters: ProposalVoter[],
  proposerId: string
): ProposalVoter | undefined {
  return voters.find(
    (voter) =>
      voter.dynamoUserId === proposerId && !voter.buyoutTransactionComplete
  );
}
