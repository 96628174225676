import { BigNumber } from "bignumber.js";
import { TypedDataUtils } from "eth-sig-util";
import { bufferToHex } from "ethereumjs-util";
import { logInfo } from "../../utils/logger";
import { ethWeb3 } from "../../magic";
import { ProposalVoter } from "../../types/common";
import * as EIP712 from "../../eip712";
import { DaoAbi } from "../../types/web3-v1-contracts/daoAbi";

export const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

export async function getUserMissingFundsFromDao(
  dao: DaoAbi,
  userAddress: string,
  userBidEth: string
): Promise<BigNumber> {
  const memberContribution = await dao.methods
    .getMemberContribution(userAddress)
    .call();

  const missingFunds = new BigNumber(ethWeb3.utils.toWei(userBidEth)).minus(
    memberContribution
  );
  logInfo(`remaining funds: ${missingFunds}`);
  return missingFunds;
}

export function getUserBid(
  voters: ProposalVoter[],
  proposerId: string
): string {
  for (const voter of voters) {
    if (voter.dynamoUserId === proposerId) {
      return String(voter.contribution);
    }
  }

  return "0";
}

// for validating all voters joined the dao
export function sumOfAddressesForDaoValidation(voters) {
  return voters.reduce(
    (sum, voter) =>
      sum.plus(new BigNumber(voter.magicWallet.toLowerCase(), 16)),
    new BigNumber(0)
  );
}

export async function orderSigner(orderEncoded, userAddress) {
  const data = EIP712.createTypeData(
    orderEncoded.signMessage.types,
    orderEncoded.signMessage.structType,
    orderEncoded.signMessage.domain,
    orderEncoded.signMessage.struct
  );
  await logInfo("signing on the message fromAddress: " + userAddress);
  const signedMessage = await EIP712.signTypedData(ethWeb3, userAddress, data);
  const hash = bufferToHex(TypedDataUtils.sign(data));
  const sig = signedMessage.sig;
  await logInfo("signature: " + sig);
  await logInfo("hash: " + hash);
  return [hash, sig];
}
