import saveTransaction from "apis/multeez/saveTransaction";
import { TransactionOriginAction } from "hooks/useCheckIfTransactionSent";
import { METHOD_GAS_LIMIT } from "utils";
import { logInfo } from "utils/logger";

type NftWithdrawSendParams = {
  userAddress: string;
  userId: string;
  userMagicAccount: string;
  dao: any;
  daoAddress: string;
  gasUnitPrice: string;
  onComplete: () => void;
};

export async function nftWithdrawSend({
  userAddress,
  userId,
  userMagicAccount,
  dao,
  daoAddress,
  gasUnitPrice,
  onComplete,
}: NftWithdrawSendParams) {
  const nftWithdrawStatus = await dao.methods
    .transferMyNFT(userAddress)
    .send({
      from: userMagicAccount,
      gasPrice: gasUnitPrice,
      gas: METHOD_GAS_LIMIT,
    })
    .on("transactionHash", (transactionHash) =>
      saveTransaction({
        transactionHash,
        userId,
        daoAddress,
        originAction: TransactionOriginAction.WithdrawNft,
        onComplete,
      })
    );
  await logInfo("NFT Withdraw Status: ");
  await logInfo(nftWithdrawStatus);
  return nftWithdrawStatus;
}
