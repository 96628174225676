import { FC, ReactNode } from "react";
import { Box } from "@mui/material";

type Props = {
  children?: ReactNode;
  index: number;
  value: number;
};

const TabPanel: FC<Props> = (props) => {
  const { children, value, index } = props;

  return (
    <Box role="tabpanel" hidden={value !== index}>
      {value === index && children}
    </Box>
  );
};

export default TabPanel;
