import { useQuery } from "react-query";
import { NFT } from "../types/common";
import { getAuthRequest } from "../utils/auth";

const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

const fetchUserNfts = async (): Promise<NFT[]> => {
  const { data } = await getAuthRequest(`${baseUrl}/api/v0/userNfts`);
  return data;
};

const useUserNftsData = () => {
  return useQuery<NFT[]>(["userNfts"], fetchUserNfts);
};

export default useUserNftsData;
