import { FC } from "react";
import { BigNumber } from "bignumber.js";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import { NFT } from "types/common";

type Props = {
  data: NFT;
};

const NftCard: FC<Props> = ({ data }) => {
  return (
    <Card raised sx={{ maxWidth: 400, width: "100%", borderRadius: 2 }}>
      <CardMedia
        component="img"
        height={158}
        image={data?.imageUrl}
        alt={data?.nftName}
      />
      <CardContent sx={{ position: "relative" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: -15,
            right: 15,
            px: 1,
            py: 0.5,
            borderRadius: 7,
            bgcolor: "#ffffff",
            boxShadow: "0px 0px 20px rgba(95, 81, 236, 0.2)",
          }}
        >
          <Typography
            variant="body1"
            fontWeight={600}
            sx={{
              background: "linear-gradient(to left, #FF8979, #FF4DCA, #E930FF)",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {new BigNumber(data?.ownershipPercentage).toString()}%
          </Typography>
        </Box>
        <Typography gutterBottom fontWeight={600} variant="body1">
          {data?.nftName}
        </Typography>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="body2">
            {data?.owners || 1} Owner
            {data && data?.owners > 1 ? "s" : ""}
          </Typography>
          <Typography variant="body2">{data?.proposedValue}ETH</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default NftCard;
