import Swal from "sweetalert2";
import { isMobile } from "react-device-detect";
import { getAuthRequest, postAuthRequest } from "./auth";
import { logInfo } from "./logger";
import { getCountryCode } from "./common";

type HandleWyreTransferParams = {
  ethAmountToAdd: number;
  userEmail: string;
  setEthAmountToAdd?: (amount: number) => void;
  setIsTransactionInProgress?: (isInProg: boolean) => void;
  onComplete?: () => void;
};

const wyreTransfer = async ({
  ethAmountToAdd,
  userEmail,
  setEthAmountToAdd,
  setIsTransactionInProgress,
  onComplete,
}: HandleWyreTransferParams) => {
  const { Wyre } = window;
  const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

  if (Number(ethAmountToAdd) <= 0) {
    Swal.fire({
      title: "Invalid amount",
      text: "Amount less than 0 is not valid",
      icon: "warning",
    });
    return;
  }

  postAuthRequest(`${baseUrl}/api/v0/fiatuse/${userEmail}`);
  const amount = Number(ethAmountToAdd);

  try {
    const country = await getCountryCode();

    const {
      data: { reservation, url },
    } = await getAuthRequest(
      `${baseUrl}/api/v0/reserve?amount=${amount}&country=${country}`
    );

    if (isMobile) {
      window.location.href = url;
      return;
    }

    const widget = new Wyre({
      env: process.env.REACT_APP_WYRE_ENVIRONMENT,
      reservation,
      operation: {
        type: "debitcard-hosted-dialog",
      },
    });

    widget.open();

    widget.on("paymentSuccess", function () {
      logInfo("Order successful with order data:");
      setIsTransactionInProgress?.(true);
      setEthAmountToAdd?.(0);
      widget.close();
      onComplete?.();
    });
  } catch (error: any) {
    setIsTransactionInProgress?.(false);
    Swal.fire({
      title: `${error?.response?.data?.data?.message}
        Please try adding funds with Metamask`,
      icon: "error",
      showConfirmButton: false,
      timer: 3000,
    });
  }
};

const openWyreWidget = async () => {
  const { Wyre } = window;
  const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

  try {
    const country = await getCountryCode();

    const {
      data: { reservation, url },
    } = await getAuthRequest(
      `${baseUrl}/api/v0/reserveGeneral?country=${country}`
    );

    if (isMobile) {
      window.location.href = url;
      return;
    }

    const widget = new Wyre({
      env: process.env.REACT_APP_WYRE_ENVIRONMENT,
      reservation,
      operation: {
        type: "debitcard-hosted-dialog",
      },
    });

    widget.open();

    widget.on("paymentSuccess", function () {
      widget.close();
    });
  } catch (error: any) {
    Swal.fire({
      title: "Error",
      text: `${error?.response?.data?.data?.message}
        Please try adding funds with Metamask`,
      icon: "error",
      showConfirmButton: false,
      timer: 3000,
    });
  }
};

export { wyreTransfer, openWyreWidget };
