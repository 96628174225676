import { useState } from "react";
import { BigNumber } from "bignumber.js";
import { Stack, Typography } from "@mui/material";
import Swal from "sweetalert2";
import { isMetaMaskInstalled, transferNft } from "../metamaskLiquidAsset";
import { getNftType } from "./utils/getNftType";
import {
  getDaoIfExists,
  calcMissingFundsForActionWei,
  DEPLOY_DAO_GAS_UNITS,
  isDaoExists,
  METHOD_GAS_LIMIT,
} from "utils";
import { ethWeb3 } from "magic";
import AddGasModal from "components/AddGasModal";
import Page from "components/Page";
import Button from "components/Button";
import Loading from "components/Loading";
import { ownershipPrct, toFixed } from "utils/common";
import { logInfo, logError } from "utils/logger";
import useUserData from "hooks/useUserData";
import useCurrentGasPrice from "hooks/useCurrentGasPrice";
import useProposalData from "hooks/useProposalData";
import { postAuthRequest } from "utils/auth";
import NftInfoCard from "components/NftInfoCard";
import Disclaimer from "components/Disclaimer";
import TransactionRecap from "components/TransactionRecap";

const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

export default function Byon() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const { data: userData } = useUserData();
  const { data: proposalData } = useProposalData(
    urlParams.get("proposalId") || ""
  );
  const gasUnitPrice = useCurrentGasPrice();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showAddGasModal, setShowAddGasModal] = useState(false);
  const [gasNeeded, setGasNeeded] = useState("0");
  const [isActionInProgress, setIsActionInProgress] = useState(false);

  const confirm = async () => {
    if (!proposalData || !userData) {
      return;
    }

    try {
      logInfo("starting BYON flow");
      setIsButtonDisabled(true);
      setIsActionInProgress(true);
      const {
        telegramChatId,
        tokenAddress: nftAddress,
        tokenID: nftTokenId,
      } = proposalData;

      logInfo(`group id for Byon request ${telegramChatId}`);
      logInfo(`nft for Byon request ${nftAddress} : ${nftTokenId}`);

      // to bring accounts
      const accounts = await ethWeb3.eth.getAccounts();
      const isMetaInstall = isMetaMaskInstalled();
      let nftType;

      if (isMetaInstall) {
        const { ethereum } = window;
        const accountsMeta = await ethereum.request({
          method: "eth_requestAccounts",
        });
        nftType = await getNftType(nftAddress, nftTokenId, accountsMeta[0]);
      } else {
        nftType = await getNftType(nftAddress, nftTokenId, accounts[0]);
      }

      if (nftType === -1) {
        Swal.fire({
          title: "Oops!",
          text: "Either this NFT is not owned by you, or it was minted lazily. You can try again with a different NFT.",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
        const notOwnedNftUrl = `${baseUrl}/api/v0/importfail/${nftAddress}/${nftTokenId}/${telegramChatId}`;
        await postAuthRequest(notOwnedNftUrl);
        return;
      }

      logInfo(`nft type for Byon request ${String(nftType)}`);

      const howMuchGasNeeded = await calcMissingFundsForActionWei({
        gasUnitPrice,
        gasUnits: DEPLOY_DAO_GAS_UNITS,
        user: userData,
        additionalFeesInWei: new BigNumber(
          proposalData?.voterAmountOwedToMulteez
        ),
      });

      if (howMuchGasNeeded.isGreaterThan("0")) {
        setGasNeeded(howMuchGasNeeded.toString());
        logInfo(
          `to low balance for request with additional needed is ${howMuchGasNeeded.toString()}`
        );
        setShowAddGasModal(true);
        return;
      }

      const daoAddress = proposalData.daoAddress;

      if (!daoAddress || !isDaoExists(proposalData)) {
        logError(`proposal ${proposalData.id} do not hold dao address`);
        return;
      }

      logInfo(`dao proposal address ${daoAddress}`);
      const dao = await getDaoIfExists(proposalData);
      logInfo(`dao after set ${dao.options.address}`);
      await dao.methods.deployPayBackForByon().send({
        from: userData.magicWallet,
        value: proposalData.voterAmountOwedToMulteez,
        gasPrice: gasUnitPrice,
        gas: METHOD_GAS_LIMIT,
      });

      await transferNft({
        contractAddress: nftAddress,
        tokenId: nftTokenId,
        to: daoAddress,
        nftType: nftType,
        proposalData: proposalData,
        onComplete: () => {
          setIsActionInProgress(false);
          Swal.fire({
            title: "Success!",
            text: "NFT has been imported successfully.",
            icon: "success",
            allowOutsideClick: false,
            allowEscapeKey: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/wallet";
            }
          });
        },
      });
    } catch (error) {
      await logError("DB Not Updated");
    }
  };

  return (
    <Page>
      {(!userData || !proposalData || isActionInProgress) && (
        <Loading text="Getting things ready..." />
      )}
      {showAddGasModal && (
        <AddGasModal
          open={showAddGasModal}
          onClose={() => setShowAddGasModal(false)}
          gasPriceInWei={gasNeeded}
          onFundsAdded={confirm}
          userEmail={userData?.email || ""}
        />
      )}
      <Stack>
        <Typography variant="h5" fontWeight={900} my={4}>
          Import NFT
        </Typography>
        <NftInfoCard
          imageUrl={proposalData?.imageUrl}
          name={proposalData?.nftName}
          owners={proposalData?.voters.length}
          headerInfo={[
            ["NFT value", `${proposalData?.proposedValue} ETH`],
            ["My ownership", `${ownershipPrct(proposalData?.slots)}%`],
          ]}
        />
        <Typography variant="subtitle2" fontWeight={900} mt={6} mb={2}>
          Transaction Recap
        </Typography>
        <TransactionRecap
          data={[
            ["NFT value", `${proposalData?.proposedValue} ETH`],
            [
              "Estimated fees*",
              `${toFixed(
                new BigNumber(
                  ethWeb3.utils.fromWei(gasUnitPrice.toString())
                ).multipliedBy(String(DEPLOY_DAO_GAS_UNITS))
              )} ETH`,
            ],
            ["Multeez service fee", "Free"],
          ]}
        />
        <Button disabled={isButtonDisabled} onClick={confirm}>
          <Typography variant="h6" fontWeight={900} component="span">
            Confirm
          </Typography>
        </Button>
        <Disclaimer />
      </Stack>
    </Page>
  );
}
