import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BigNumber } from "bignumber.js";
import Swal from "sweetalert2";
import { InputAdornment, Stack, Typography } from "@mui/material";
import BottomDrawer from "../BottomDrawer";
import * as S from "./Transfer.styles";
import { ethWeb3 } from "magic";
import {
  amountMinusGasPrice,
  GAS_PRICE_GUARD_MULTIPLIER,
  METHOD_GAS_LIMIT,
  SINGLE_TRANSACTION_GAS_LIMIT,
} from "utils";
import { useUser } from "pages/Wallet";
import Button from "components/Button";
import { toFixed } from "utils/common";
import Loading from "components/Loading";
import { logInfo } from "utils/logger";

const Transfer = () => {
  const navigate = useNavigate();
  const { userData, userFreeBalance } = useUser();
  const [disableButton, setDisableButton] = useState(false);
  const [isTransactionInProgress, setIsTransactionInProgress] = useState(false);
  const [gasUnitPrice, setGasUnitPrice] = useState("0");
  const [withdrawAddress, setWithdrawAddress] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [addressError, setAddressError] = useState(false);
  const [isAddressTouched, setIsAddressTouched] = useState(false);
  const [isAmountTouched, setIsAmountTouched] = useState(false);

  useEffect(() => {
    setDisableButton(true);
    // lock gas price in order to give a visual estimate to the user
    ethWeb3.eth.getGasPrice().then((price) => {
      const gasPriceAfterMul = new BigNumber(price)
        .multipliedBy(GAS_PRICE_GUARD_MULTIPLIER)
        .toFixed(0);
      setGasUnitPrice(gasPriceAfterMul);
    });
    setDisableButton(false);
  }, []);

  const getBalances = async () => {
    const lockedFundsETH = userData?.lockedFunds;
    const lockedFundsWei = ethWeb3.utils.toWei(String(lockedFundsETH));
    logInfo(`user locked funds in Wei ${String(lockedFundsWei)}`);
  };

  useEffect(() => {
    if (!isNaN(userData?.lockedFunds || 0)) {
      getBalances();
    }
  }, [userData?.lockedFunds]);

  const shouldDisable = () =>
    !userData ||
    addressError ||
    !withdrawAddress ||
    Number(withdrawAmount) <= 0;

  const lockGasPriceAndRun = async () => {
    ethWeb3.eth
      .getGasPrice()
      .then((price) => {
        const gasPriceAfterMul = new BigNumber(price)
          .multipliedBy(GAS_PRICE_GUARD_MULTIPLIER)
          .toFixed(0);
        setGasUnitPrice(gasPriceAfterMul);
        logInfo(`gas price for the transaction ${gasPriceAfterMul}`);
      })
      .then(confirm);
  };

  const confirm = async () => {
    if (!userData) {
      return;
    }

    try {
      setDisableButton(true);
      setIsTransactionInProgress(true);
      let ethToSend = new BigNumber(0);
      const accounts = await ethWeb3.eth.getAccounts();
      const userAddress = accounts[0];
      const userBalance = await ethWeb3.eth.getBalance(userAddress); // account balance in wie
      const lockedFundsETH = userData?.lockedFunds;
      const lockedFundsWei = ethWeb3.utils.toWei(String(lockedFundsETH));
      logInfo(
        `user locked funds in Wei after confirm button ${String(
          lockedFundsWei
        )}`
      );
      const freeBalanceBN = new BigNumber(userFreeBalance);

      logInfo(
        `user balance in Wei after confirm button ${String(userBalance)}`
      );

      logInfo(`user requested to send funds to ${String(withdrawAddress)}`);

      const withdrawAmountBN = new BigNumber(withdrawAmount);

      if (withdrawAmountBN.isGreaterThan(freeBalanceBN.toFixed(4))) {
        const freeBalanceETH = Number(
          ethWeb3.utils.fromWei(String(freeBalanceBN))
        ).toFixed(4);
        Swal.fire({
          title: `You have a free balance of ${freeBalanceETH} ETH!`,
          icon: "info",
          showConfirmButton: false,
          timer: 300,
        });
        return "";
      }

      logInfo(
        `user requested to send amount of ${withdrawAmountBN.toString()}`
      );

      if (withdrawAmountBN.isEqualTo(freeBalanceBN.toFixed(4))) {
        ethToSend = new BigNumber(freeBalanceBN);
      } else {
        ethToSend = withdrawAmountBN;
      }

      let wieToSend;

      if (ethToSend.isEqualTo(freeBalanceBN)) {
        wieToSend = await amountMinusGasPrice(
          userData,
          userAddress,
          gasUnitPrice
        );
      } else {
        wieToSend = ethWeb3.utils.toWei(ethToSend.toString()).toString();
      }

      logInfo(`sending amount of ${wieToSend}`);
      logInfo(`gas final price
      ${new BigNumber(gasUnitPrice)
        .multipliedBy(SINGLE_TRANSACTION_GAS_LIMIT)
        .toString()}`);
      logInfo(`free balance ${freeBalanceBN.toString()}`);
      const send = await ethWeb3.eth.sendTransaction({
        from: userAddress,
        to: withdrawAddress,
        value: wieToSend,
        gas: SINGLE_TRANSACTION_GAS_LIMIT,
        gasPrice: gasUnitPrice,
      });
      console.log(send);

      setIsTransactionInProgress(false);
      Swal.fire({
        title: "Success!",
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
      await getBalances();
      navigate("/wallet");
    } catch (e) {
      Swal.fire({
        title: "Failed to withdraw funds",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
      console.log(e);
    } finally {
      setIsTransactionInProgress(false);
    }
  };

  const handleAddressChange = useCallback((event) => {
    setIsAddressTouched(true);

    if (event.target.value === userData?.magicWallet) {
      setAddressError(true);
    }

    if (event.target.value) {
      setWithdrawAddress(event.target.value);
    } else {
      setWithdrawAddress("magic");
    }
  }, []);

  const handleAddressBlur = () =>
    setAddressError(withdrawAddress === userData?.magicWallet ? true : false);

  const handleAmountChange = useCallback(({ target: { value } }) => {
    setIsAmountTouched(true);

    if (!value) {
      setWithdrawAmount("");
      return;
    }

    const numberRegex = /^\d+\.?\d*$/;

    numberRegex.test(value) && setWithdrawAmount(value);
  }, []);

  return (
    <>
      {(!userData || isTransactionInProgress) && (
        <Loading
          text={
            isTransactionInProgress
              ? "Processing withdraw request..."
              : "Getting things ready..."
          }
        />
      )}
      <BottomDrawer>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4}
        >
          <Stack direction="row" alignItems="center">
            <S.ArrowIcon />
            <Typography variant="body1" fontWeight={600} align="center">
              Transfer
            </Typography>
          </Stack>
          <S.CloseIcon onClick={() => navigate("/wallet")} />
        </Stack>
        <Stack gap={3}>
          <S.StyledInput
            variant="standard"
            touched={isAddressTouched}
            onChange={handleAddressChange}
            onBlur={handleAddressBlur}
            error={addressError}
            placeholder="Public address"
            helperText={
              addressError ? "The address you typed is your Multeez wallet" : ""
            }
          />
          <Stack gap={1}>
            <S.StyledInput
              touched={isAmountTouched}
              value={withdrawAmount}
              onChange={handleAmountChange}
              variant="standard"
              placeholder="Amount to transfer"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">ETH</InputAdornment>
                ),
              }}
            />
          </Stack>
          <Button
            sx={{ mt: 6 }}
            disabled={disableButton || shouldDisable()}
            onClick={lockGasPriceAndRun}
          >
            <Typography variant="h6" fontWeight={900} component="span">
              Confirm
            </Typography>
          </Button>
          <Typography variant="caption" align="center">
            Estimated Gas Price:{" "}
            <strong>
              {toFixed(
                new BigNumber(
                  ethWeb3.utils.fromWei(gasUnitPrice.toString())
                ).multipliedBy(String(METHOD_GAS_LIMIT))
              )}{" "}
              ETH
            </strong>
          </Typography>
        </Stack>
      </BottomDrawer>
    </>
  );
};

export default Transfer;
