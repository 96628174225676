import { useQuery } from "react-query";
import axios from "axios";
import { Proposal } from "../types/common";
import { getAuthHeader } from "../utils/auth";
import { logInfo } from "../utils/logger";

const baseUrl = process.env.REACT_APP_CLIENT_API_ENDPOINT;

const fetchProposalData = async (
  daoAddress: string
): Promise<Proposal | undefined> => {
  const { data } = await axios.get(
    `${baseUrl}/api/v0/proposalByDao/${daoAddress}`,
    {
      headers: await getAuthHeader(),
    }
  );

  logInfo(`Proposal with dao address ${daoAddress} fetched`);
  return data;
};

export default function (daoAddress: string) {
  return useQuery<Proposal | undefined>(
    ["proposalData"],
    () => fetchProposalData(daoAddress),
    {
      enabled: Boolean(daoAddress),
    }
  );
}
