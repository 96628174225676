import { useState, useEffect } from "react";
import { BigNumber } from "bignumber.js";
import { Typography, Stack } from "@mui/material";
import Swal from "sweetalert2";
import { getUserVoterData } from "../BuyOut/utils";
import { orderSigner, updateUserHash } from "./utils";
import { ProposalStatus, ProposalVoter } from "types/common";
import { ethWeb3 } from "magic";
import {
  calcMissingFundsForActionWei,
  METHOD_GAS_LIMIT,
  runWithRetries,
  createContractInstance,
  MULTEEZ_FEE_PRCT,
} from "utils";
import AddGasModal from "components/AddGasModal";
import Page from "components/Page";
import Button from "components/Button";
import Loading from "components/Loading";
import { ownershipPrct, toFixed } from "utils/common";
import { logError, logInfo } from "utils/logger";
import useUserData from "hooks/useUserData";
import useProposalData from "hooks/useProposalData";
import useCurrentGasPrice from "hooks/useCurrentGasPrice";
import useCheckIfTransactionSent, {
  TransactionOriginAction,
} from "hooks/useCheckIfTransactionSent";
import rarible from "apis/rarible/client";
import NftInfoCard from "components/NftInfoCard";
import TransactionRecap from "components/TransactionRecap";
import Disclaimer from "components/Disclaimer";

export default function Sell() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const gasUnitPrice = useCurrentGasPrice();
  const { data: userData } = useUserData();
  const { data: proposalData } = useProposalData(
    urlParams.get("proposalId") || ""
  );

  const [disableButton, setDisableButton] = useState(false);
  const [showAddGasModal, setShowAddGasModal] = useState(false);
  const [gasNeeded, setGasNeeded] = useState("0");
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [finalUserRevenueAmount, setFinalUserRevenueAmount] = useState(0);
  const [userSlots, setUserSlots] = useState(0);

  useEffect(() => {
    if (!proposalData || !userData) {
      return;
    }

    setDisableButton(true);

    const userSellerData = getUserVoterData(proposalData.voters, userData.id);

    if (!userSellerData) {
      return;
    }

    const proposalVal = new BigNumber(proposalData?.proposedValue);
    const revenuePercent = new BigNumber(1).minus(MULTEEZ_FEE_PRCT);
    const userSellerSlots = userSellerData.slots || 0;
    const finalUserRevenue = proposalVal
      .multipliedBy(revenuePercent)
      .multipliedBy(userSellerSlots)
      .dividedBy(proposalData.slots);
    setFinalUserRevenueAmount(Number(finalUserRevenue.toFixed(5)));
    setUserSlots(userSellerSlots);
    setDisableButton(false);
  }, [proposalData]);

  const shouldDisable = () => !proposalData || !userData;

  useCheckIfTransactionSent({
    user: userData,
    proposal: proposalData,
    originAction: TransactionOriginAction.Sell,
  });

  const handleConfirm = async () => {
    if (!proposalData || !userData) {
      return;
    }

    try {
      setIsActionInProgress(true);
      setDisableButton(true);
      const accounts = await ethWeb3.eth.getAccounts();
      const userAddress = accounts[0];
      const howMuchGasNeeded = await calcMissingFundsForActionWei({
        gasUnitPrice,
        gasUnits: METHOD_GAS_LIMIT,
        user: userData,
      });

      if (!howMuchGasNeeded.isEqualTo("0")) {
        setGasNeeded(howMuchGasNeeded.toString());
        await logInfo(
          `to low balance for request with additional needed is ${howMuchGasNeeded.toString()}`
        );
        setShowAddGasModal(true);
        return;
      }

      await logInfo("proposal data like in request");
      await logInfo(JSON.stringify(proposalData));
      const nftAddress = proposalData?.tokenAddress;
      const nftId = proposalData?.tokenID;
      const daoAddress = proposalData?.daoAddress;
      const sellPriceEther = proposalData?.proposedValue;
      const sellBidPrice = ethWeb3.utils.toWei(String(sellPriceEther));

      if (proposalData?.proposalStatus !== ProposalStatus.CommitmentNotFull) {
        await logInfo("Proposal is no longer awaiting commitment");
        Swal.fire(
          "Sorry!",
          "Proposal is no longer awaiting commitment",
          "warning"
        );
        return;
      }

      // Sign the order
      const order = await rarible.createDirectSellOrder(
        nftAddress,
        nftId,
        sellBidPrice
      );
      logInfo(JSON.stringify(order));

      if (!order) {
        logInfo("can not make a bid on NFT");
        Swal.fire({
          title: "Invalid NFT for bid",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      await logInfo("creating dao instance");
      const dao = await runWithRetries(createContractInstance, daoAddress);

      if (!dao) {
        await logError(
          "DAO instance failed to be created user needs to try again later, no money was spent"
        );
        alert(
          "DAO instance failed to be created please try again later, no money was spent"
        );
        return;
      }

      order.maker = daoAddress; // adding the dao address to the order
      const orderEncoded = await rarible.encodeOrder(order);
      const [hash, sig] = await orderSigner(orderEncoded, userAddress, ethWeb3);
      order.signature = sig;
      //dao.methods.updateMyHash(hash,sig).send({from : userAddress,gasPrice:gasUnitPrice});
      await updateUserHash([
        hash,
        sig,
        dao,
        userAddress,
        gasUnitPrice,
        proposalData,
        userData.id,
        order,
        setIsActionInProgress,
      ]);
      await logInfo("update hash response");
    } catch (e) {
      Swal.fire({
        title: "Failed to create bid on Rarible",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
      console.log(e);
    }
  };

  return (
    <Page>
      {(!userData || isActionInProgress) && (
        <Loading
          text={`${
            !userData ? "Loading" : "Getting things ready"
          }, please wait...`}
        />
      )}
      {showAddGasModal && (
        <AddGasModal
          open={showAddGasModal}
          onClose={() => setShowAddGasModal(false)}
          gasPriceInWei={gasNeeded}
          onFundsAdded={confirm}
          userEmail={userData?.email || ""}
        />
      )}
      <Stack>
        <Typography variant="h5" fontWeight={900} my={4}>
          Sale confirmation
        </Typography>
        <NftInfoCard
          imageUrl={proposalData?.imageUrl}
          name={proposalData?.nftName}
          owners={proposalData?.voters.length}
          type="sell"
          proposedSeats={proposalData?.proposedShare}
          proposedValue={proposalData?.proposedValue}
          headerInfo={[
            [
              "My ownership",
              `${userSlots} seats of ${proposalData?.slots} total seats`,
            ],
          ]}
        />
        <Typography variant="subtitle2" fontWeight={900} mt={6} mb={2}>
          Transaction Recap
        </Typography>
        <TransactionRecap
          data={[
            ["Your expected revenue", `${finalUserRevenueAmount} ETH`],
            [
              "Estimated fees*",
              `${toFixed(
                new BigNumber(
                  ethWeb3.utils.fromWei(gasUnitPrice.toString())
                ).multipliedBy(String(METHOD_GAS_LIMIT))
              )} ETH`,
            ],
            ["Multeez service fee", "Free"],
          ]}
        />
        <Button
          disabled={disableButton || shouldDisable()}
          onClick={handleConfirm}
        >
          <Typography variant="h6" fontWeight={900} component="span">
            Confirm
          </Typography>
        </Button>
        <Disclaimer />
      </Stack>
    </Page>
  );
}
