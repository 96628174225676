import { TypedDataUtils } from "eth-sig-util";
import { bufferToHex } from "ethereumjs-util";
import * as EIP712 from "eip712";
import saveTransaction from "apis/multeez/saveTransaction";
import { logInfo } from "utils/logger";
import { TransactionOriginAction } from "hooks/useCheckIfTransactionSent";
import { METHOD_GAS_LIMIT } from "utils";

export async function orderSigner(orderEncoded, userAddress, ethWeb3) {
  const data = EIP712.createTypeData(
    orderEncoded.signMessage.types,
    orderEncoded.signMessage.structType,
    orderEncoded.signMessage.domain,
    orderEncoded.signMessage.struct
  );
  await logInfo("fromAddress: " + userAddress);
  const signedMessage = await EIP712.signTypedData(ethWeb3, userAddress, data);
  const hash = bufferToHex(TypedDataUtils.sign(data));
  const sig = signedMessage.sig;
  await logInfo("signature: " + sig);
  await logInfo("hash: " + hash);
  return [hash, sig];
}

export async function updateUserHash([
  hash,
  sig,
  dao,
  userAddress,
  gasUnitPrice,
  proposal,
  userId,
  order,
  setIsActionInProgress,
]) {
  dao.methods
    .updateMyHash(hash, sig)
    .send({ from: userAddress, gasPrice: gasUnitPrice, gas: METHOD_GAS_LIMIT })
    .on("transactionHash", (transactionHash) =>
      saveTransaction({
        transactionHash,
        proposal,
        userId,
        originAction: TransactionOriginAction.Sell,
        onComplete: () => setIsActionInProgress(false),
        transactionOrder: order,
      })
    );
}
