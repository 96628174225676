import {
  ProposalVoter,
  ProposalKind,
  ProposalStatus,
  Proposal,
} from "types/common";

const STATUS_COLOR = {
  inProgress: "#EF32D9, #89FFFD",
  failed: "#FFAFBD, #FFC3A0",
  success: "#4ACF8C, #75EDA6",
};

const checkFailedProposal = (proposal: Proposal) => {
  if (proposal?.proposalStatus === ProposalStatus.Failed) {
    if (proposal?.proposeKind === ProposalKind.Buy) {
      if (proposal?.isDirectBuy) {
        return {
          text: "Purchase failed to complete. You can /cashout funds from the proposal",
          color: STATUS_COLOR.failed,
        };
      } else {
        return {
          text: "Proposal was outbid. You can /cashout funds from the proposal",
          color: STATUS_COLOR.failed,
        };
      }
    } else if (proposal?.proposeKind === ProposalKind.Buyout) {
      return {
        text: "Transaction failed to complete",
        color: STATUS_COLOR.failed,
      };
    }
  }
};

const checkBidPlaced = (proposal: Proposal) => {
  if (proposal?.proposalStatus === ProposalStatus.BidPlaced) {
    if (proposal?.proposeKind === ProposalKind.Buy) {
      return {
        text: "Bid placed on the market place",
        color: STATUS_COLOR.inProgress,
      };
    }
  }
};

const checkWonProposal = (proposal: Proposal) => {
  if (proposal?.proposalStatus === ProposalStatus.Won) {
    if (proposal?.proposeKind === ProposalKind.Buyout) {
      return {
        text: "Congrats! The purchase has been completed successfully",
        color: STATUS_COLOR.success,
      };
    } else if (proposal?.proposeKind === ProposalKind.Buy) {
      if (proposal?.isDirectBuy) {
        return {
          text: "Congrats! The purchase has been completed successfully",
          color: STATUS_COLOR.success,
        };
      } else {
        return {
          text: "Congrats! Bid won!",
          color: STATUS_COLOR.success,
        };
      }
    }
  }
};

const checkClosedProposal = ({
  userId,
  proposal,
  proposerName,
}: {
  userId: string;
  proposal: Proposal;
  proposerName: string;
}) => {
  if (proposal?.closed) {
    if (proposal?.proposeKind === ProposalKind.Buy) {
      if (proposal?.proposer === userId) {
        return {
          text: `You cancelled the proposal to group buy "${proposal?.nftName}"`,
          color: STATUS_COLOR.failed,
        };
      } else {
        return {
          text: `The proposal to group buy "${proposal?.nftName}" was cancelled`,
          color: STATUS_COLOR.failed,
        };
      }
    } else if (proposal?.proposeKind === ProposalKind.Buyout) {
      if (proposal?.proposer === userId) {
        return {
          text: `You cancelled the proposal to sell your seat in "${proposal?.nftName}"`,
          color: STATUS_COLOR.failed,
        };
      } else {
        return {
          text: `${proposerName} cancelled the proposal to sell their seat in "${proposal?.nftName}"`,
          color: STATUS_COLOR.failed,
        };
      }
    } else if (proposal?.proposeKind === ProposalKind.Sell) {
      return {
        text: `The proposal to sell "${proposal?.nftName}" has been rejected`,
        color: STATUS_COLOR.failed,
      };
    }
  }
};

const checkFundsLocked = (proposal: Proposal, voter?: ProposalVoter) => {
  if (voter?.fundsLocked) {
    if (proposal?.proposeKind === ProposalKind.Buy) {
      return {
        text: `${voter?.contribution} ETH were locked in your wallet`,
        color: STATUS_COLOR.inProgress,
      };
    } else if (proposal?.proposeKind === ProposalKind.Sell) {
      return {
        text: `The proposal to sell "${proposal?.nftName}" has been approved`,
        color: STATUS_COLOR.inProgress,
      };
    }
  }
};

const checkFundsTransferred = ({
  userId,
  proposal,
  voter,
}: {
  userId: string;
  proposal: Proposal;
  voter?: ProposalVoter;
}) => {
  if (voter?.fundsTransferred) {
    if (proposal?.proposeKind === ProposalKind.Buy) {
      return {
        text: "Transaction confirmed. Awaiting approval from Rarible",
        color: STATUS_COLOR.inProgress,
      };
    } else if (proposal?.proposeKind === ProposalKind.Buyout) {
      if (proposal?.proposer === userId) {
        return {
          text: "Transaction confirmed. Awaiting approval from buyer",
          color: STATUS_COLOR.inProgress,
        };
      } else {
        return {
          text: "Transaction confirmed. Ownership transfer is in progress",
          color: STATUS_COLOR.inProgress,
        };
      }
    } else if (proposal?.proposeKind === ProposalKind.Sell) {
      return {
        text: "Transaction confirmed. Awaiting approval from Rarible",
        color: STATUS_COLOR.inProgress,
      };
    }
  }
};

const checkDefault = ({
  userId,
  proposal,
  proposerName,
  voter,
}: {
  userId: string;
  proposal: Proposal;
  proposerName: string;
  voter?: ProposalVoter;
}) => {
  if (proposal?.proposer === userId) {
    if (proposal?.proposeKind === ProposalKind.Buy) {
      return {
        text: `You proposed to group buy "${proposal?.nftName}" and saved a seat`,
        color: STATUS_COLOR.inProgress,
      };
    } else if (proposal?.proposeKind === ProposalKind.Buyout) {
      return {
        text: `You proposed to sell portion of "${proposal?.nftName}"`,
        color: STATUS_COLOR.inProgress,
      };
    } else if (proposal?.proposeKind === ProposalKind.Sell) {
      return {
        text: `You proposed co-owners of "${proposal?.nftName}" to sell`,
        color: STATUS_COLOR.inProgress,
      };
    }
  } else {
    if (proposal?.proposeKind === ProposalKind.Buy) {
      return {
        text: `You saved a seat on the  proposal to buy "${proposal?.nftName}"`,
        color: STATUS_COLOR.inProgress,
      };
    } else if (proposal?.proposeKind === ProposalKind.Buyout) {
      return {
        text: `You asked ${proposerName} to buy their ownership of "${proposal?.nftName}"`,
        color: STATUS_COLOR.inProgress,
      };
    } else if (proposal?.proposeKind === ProposalKind.Sell) {
      if (voter?.approveSell) {
        return {
          text: `You approved to list "${proposal?.nftName}" for sale`,
          color: STATUS_COLOR.inProgress,
        };
      } else {
        return {
          text: "You have a sale proposal waiting",
          color: STATUS_COLOR.inProgress,
        };
      }
    }
  }
};

export const getProposalStatus = (userId: string, proposal: Proposal) => {
  const proposerName = proposal?.proposer?.split("_")[1];

  const failedStatus = checkFailedProposal(proposal);

  const wonStatus = checkWonProposal(proposal);

  const closedStatus = checkClosedProposal({ userId, proposal, proposerName });

  const voter = proposal?.voters?.find(
    (voter: ProposalVoter) => voter.dynamoUserId === userId
  );

  const fundsLockedStatus = checkFundsLocked(proposal, voter);

  const fundsTransferredStatus = checkFundsTransferred({
    userId,
    proposal,
    voter,
  });

  const bidPlacedStatus = checkBidPlaced(proposal);

  const defaultStatus = checkDefault({ userId, proposal, proposerName, voter });

  // order of returned statuses is important
  return (
    wonStatus ||
    closedStatus ||
    failedStatus ||
    bidPlacedStatus ||
    fundsTransferredStatus ||
    fundsLockedStatus ||
    defaultStatus
  );
};
