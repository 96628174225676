import { ethWeb3 } from "magic";
import { ERC721ABI } from "contract/ERC721";
import { logInfo, logError } from "utils/logger";
import { ERC1155 } from "contract/ERC1155";
import { ERC721NameConvention, ERC1155NameConvention } from "utils";

export async function getNftType(
  nftAddress: string,
  tokenId: string,
  account: string
) {
  const nftAttempt = new ethWeb3.eth.Contract(ERC721ABI.abi, nftAddress);

  try {
    const res = await nftAttempt.methods.ownerOf(tokenId).call();

    if (String(res).toLowerCase() !== account.toLowerCase()) {
      return -1;
    }

    return ERC721NameConvention;
  } catch (e) {
    await logInfo(
      `the ${String(nftAddress)} and id ${String(tokenId)} holds is not ERC721`
    );

    try {
      const nftAttempt1155 = await new ethWeb3.eth.Contract(
        ERC1155.abi,
        nftAddress
      );
      const resERC1155 = await nftAttempt1155.methods
        .balanceOf(account, tokenId)
        .call();
      await logInfo(
        `the Balance of ${String(account)} on nft ${String(
          nftAddress
        )} and id ${String(tokenId)} is ${resERC1155}`
      );

      if (resERC1155 === 0) {
        await logInfo(
          `The nft ${String(nftAddress)} and id ${String(
            tokenId
          )} might be lazy or not owned by user`
        );
        return -1;
      }

      return ERC1155NameConvention;
    } catch (eErc1155) {
      await logError(
        `Error when trying to figure out nft type and holder might be lazy minting, with error:`
      );
      await logError(eErc1155);
    }

    return -1;
  }
}
