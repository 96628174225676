import { useCallback, useState } from "react";
import { BigNumber } from "bignumber.js";
import Swal from "sweetalert2";
import { Stack, TextField, Typography } from "@mui/material";
import { nftWithdrawSend } from "./utils";
import { ethWeb3 } from "magic";
import {
  calcMissingFundsForActionWei,
  METHOD_GAS_LIMIT,
  runWithRetries,
  createContractInstance,
} from "utils";
import AddGasModal from "components/AddGasModal";
import Page from "components/Page";
import Button from "components/Button";
import { ownershipPrct, toFixed } from "utils/common";
import { logError, logInfo } from "utils/logger";
import Loading from "components/Loading";
import useUserData from "hooks/useUserData";
import useProposalDataByDao from "hooks/useProposalDataByDao";
import useCurrentGasPrice from "hooks/useCurrentGasPrice";
import useCheckIfTransactionSent, {
  TransactionOriginAction,
} from "hooks/useCheckIfTransactionSent";
import NftInfoCard from "components/NftInfoCard";
import TransactionRecap from "components/TransactionRecap";
import Disclaimer from "components/Disclaimer";

export default function WithdrawNft() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const gasUnitPrice = useCurrentGasPrice();
  const { data: userData } = useUserData();
  const { data: proposalData } = useProposalDataByDao(
    urlParams.get("daoAddress") || ""
  );
  const [disableButton, setDisableButton] = useState(false);
  const [showAddGasModal, setShowAddGasModal] = useState(false);
  const [gasNeeded, setGasNeeded] = useState("0");
  const [withdrawAddress, setWithdrawAddress] = useState("");
  const [isActionInProgress, setIsActionInProgress] = useState(false);

  useCheckIfTransactionSent({
    user: userData,
    originAction: TransactionOriginAction.WithdrawNft,
  });

  const handleConfirm = async () => {
    if (!userData || !proposalData) return;

    try {
      setDisableButton(true);
      setIsActionInProgress(true);
      const accounts = await ethWeb3.eth.getAccounts();
      let userAddress;

      if (withdrawAddress === "magic") {
        userAddress = accounts[0];
      } else {
        userAddress = withdrawAddress;
      }

      const daoAddress = urlParams.get("daoAddress") || "";

      const userMagicAccount = accounts[0];

      const howMuchGasNeeded = await calcMissingFundsForActionWei({
        gasUnitPrice,
        gasUnits: METHOD_GAS_LIMIT,
        user: userData,
      });

      if (!howMuchGasNeeded.isEqualTo("0")) {
        setGasNeeded(howMuchGasNeeded.toString());
        await logInfo(
          `to low balance for request with additional needed is ${howMuchGasNeeded.toString()}`
        );
        setShowAddGasModal(true);
        return;
      }

      const dao = await runWithRetries(createContractInstance, daoAddress);

      if (!dao) {
        await logError("DAO failed to be created please try again later");
        alert("DAO failed to be created please try again later");
        return "";
      }

      const params = {
        userAddress,
        userId: userData?.id || "",
        userMagicAccount,
        dao,
        daoAddress,
        gasUnitPrice,
        onComplete: () => setIsActionInProgress(false),
      };
      await runWithRetries(nftWithdrawSend, params);
    } catch (e) {
      Swal.fire({
        title: "Failed to withdraw nft",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
      await logError("failed to withdraw nft with general error: ");
      await logError(e);
    }
  };

  const handleInputOnChange = useCallback((event) => {
    if (event.target.value) {
      setWithdrawAddress(event.target.value);
    } else {
      setWithdrawAddress("magic");
    }
  }, []);

  const shouldDisable = () => !userData;

  return (
    <Page>
      {(!userData || !proposalData || isActionInProgress) && (
        <Loading text="Getting things ready..." />
      )}
      {showAddGasModal && (
        <AddGasModal
          open={showAddGasModal}
          onClose={() => setShowAddGasModal(false)}
          gasPriceInWei={gasNeeded}
          onFundsAdded={confirm}
          userEmail={userData?.email}
        />
      )}
      <Stack>
        <Typography variant="h5" fontWeight={900} my={4}>
          Export NFT
        </Typography>
        <NftInfoCard
          imageUrl={proposalData?.imageUrl}
          name={proposalData?.nftName}
          owners={proposalData?.voters.length}
          headerInfo={[
            ["NFT value", `${proposalData?.proposedValue} ETH`],
            ["My ownership", `${ownershipPrct(proposalData?.slots)}%`],
          ]}
        />
        <Stack
          direction="row"
          height={52}
          borderRadius={3}
          my={3}
          px={2}
          bgcolor="#ffffff"
          alignItems="center"
        >
          <Typography mr={1} variant="subtitle2" fontWeight={900}>
            Send NFT to
          </Typography>
          <TextField
            sx={{ flex: 1 }}
            type="text"
            variant="standard"
            onChange={handleInputOnChange}
          />
        </Stack>
        <Typography variant="subtitle2" fontWeight={900} mt={1} mb={2}>
          Transaction Recap
        </Typography>
        <TransactionRecap
          data={[
            ["NFT value", `${proposalData?.proposedValue} ETH`],
            [
              "Estimated fees*",
              `${toFixed(
                new BigNumber(
                  ethWeb3.utils.fromWei(gasUnitPrice.toString())
                ).multipliedBy(String(METHOD_GAS_LIMIT))
              )} ETH`,
            ],
            ["Multeez service fee", "Free"],
          ]}
        />
        <Button
          disabled={disableButton || shouldDisable()}
          onClick={handleConfirm}
        >
          <Typography variant="h6" fontWeight={900} component="span">
            Confirm
          </Typography>
        </Button>
        <Disclaimer />
      </Stack>
    </Page>
  );
}
