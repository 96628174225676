import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  Proposal,
  Transaction,
  TransactionStatus,
  User,
} from "../types/common";
import { baseUrl } from "../pages/Deploy/utils";
import { CheckIfTransactionSentParams } from "../pages/Deploy/types";
import { getAuthRequest } from "../utils/auth";
import { logError, logInfo } from "../utils/logger";

export enum TransactionOriginAction {
  AddFundsToDao = "addFundsToDao",
  DirectBuy = "directBuy",
  RequestBuyout = "requestBuyout",
  Buyout = "buyout",
  Byon = "byon",
  Sell = "sell",
  Withdraw = "withdraw",
  WithdrawNft = "withdrawNft",
}

export type UseCheckIfTransactionSentParams = {
  proposal?: Proposal;
  user?: User;
  originAction: TransactionOriginAction;
  hideAlert?: boolean;
};

export const useCheckIfTransactionSent = ({
  user,
  proposal,
  originAction,
  hideAlert,
}: UseCheckIfTransactionSentParams) => {
  const [transactionStatus, setTransactionStatus] = useState<{
    sent: boolean;
    status?: TransactionStatus;
  }>({
    sent: false,
    status: undefined,
  });

  useEffect(() => {
    if (!user || !proposal) {
      return;
    }

    const checkIfTransactionInProgress = async () => {
      const transaction = await checkIfTransactionSent({
        proposalId: proposal?.id || "",
        userId: user.id || "",
        originAction: originAction,
        proposalTime: String(proposal?.proposalTime),
      });

      if (transaction) {
        setTransactionStatus({ sent: true, status: transaction.status });
        const text =
          transaction.status === TransactionStatus.Pending
            ? "Transaction is currently in progress. You will be notified in telegram upon completion."
            : "Transaction was successful! You may close the page.";

        const title =
          transaction.status === TransactionStatus.Pending
            ? "In Progress"
            : "Success";

        if (hideAlert && transaction.status !== TransactionStatus.Pending) {
          return;
        }

        Swal.fire({
          title,
          text,
          icon:
            transaction.status === TransactionStatus.Success
              ? "success"
              : "info",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/wallet";
          }
        });
      }
    };

    checkIfTransactionInProgress();
  }, [user, proposal]);

  return transactionStatus;
};

export async function checkIfTransactionSent({
  proposalId,
  userId,
  originAction,
  proposalTime,
}: CheckIfTransactionSentParams): Promise<Transaction | undefined> {
  try {
    const userTransactionEndPoint = `${baseUrl}/api/v0/transaction?proposalId=${proposalId}&userDynamoId=${userId}&originAction=${originAction}&proposalTime=${proposalTime}`;
    const transactionResponse = await getAuthRequest(userTransactionEndPoint);
    logInfo(
      `transaction data response ${JSON.stringify(transactionResponse.data)}`
    );
    return transactionResponse?.data;
  } catch (e) {
    logError(e);
    return undefined;
  }
}

export default useCheckIfTransactionSent;
