import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BigNumber } from "bignumber.js";
import { InputAdornment, Stack, Typography } from "@mui/material";
import BottomDrawer from "../BottomDrawer";
import * as S from "./Deposit.styles";
import { useUser } from "pages/Wallet";
import AddFundsButton from "components/AddFundsButton";
import useQueryParams from "hooks/useQueryParams";
import { wyreTransfer } from "utils/wyreTransfer";
import { isMetaMaskInstalled, metamaskTransfer } from "utils/metamaskTransfer";

const Deposit = () => {
  const navigate = useNavigate();
  const { userData } = useUser();
  const queryParams = useQueryParams();
  const minimumAmount = queryParams.get("minimalDeposit")
    ? new BigNumber(queryParams.get("minimalDeposit")).plus(0.0001).toString()
    : "0.1";
  const [amountError, setAmountError] = useState(false);
  const [isInputTouched, setIsInputTouched] = useState(false);
  const [depositAmount, setDepositAmount] = useState<string>("1.0");
  const [, setIsTransactionInProgress] = useState(false);

  const onAmountChange = ({ target: { value } }) => {
    setIsInputTouched(true);

    if (!value) {
      setDepositAmount("");
      return;
    }

    const numberRegex = /^\d+\.?\d*$/;

    numberRegex.test(value) && setDepositAmount(value);
  };

  const onBlur = () =>
    setAmountError(
      depositAmount
        ? new BigNumber(depositAmount).isLessThan(minimumAmount)
        : true
    );

  const handleWyreClick = useCallback(() => {
    wyreTransfer({
      ethAmountToAdd: Number(depositAmount) || 0,
      userEmail: userData?.email || "",
      onComplete: () => navigate("/wallet"),
    });
  }, [userData, depositAmount]);

  const handleMetaMaskClick = useCallback(() => {
    metamaskTransfer({
      publicAddress: userData?.magicWallet || "",
      amountToSendInEth: depositAmount || "0",
      setIsTransactionInProgress,
      setEthAmountToAdd: (amount: number) => setDepositAmount(String(amount)),
      onComplete: () => navigate("/wallet"),
      userEmail: userData?.email || "",
    });
  }, [userData, depositAmount]);

  return (
    <BottomDrawer>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Stack direction="row" alignItems="center">
          <S.ArrowIcon />
          <Typography variant="body1" fontWeight={600} align="center">
            Deposit
          </Typography>
        </Stack>
        <S.CloseIcon onClick={() => navigate("/wallet")} />
      </Stack>
      <Typography variant="body2" mb={6}>
        As gas fluctuates, we recommend adding another 10% to your intended
        deposit. Any unused funds will remain in your wallet available for
        withdrawal
      </Typography>
      <Stack gap={3}>
        <S.Input
          touched={isInputTouched}
          value={depositAmount}
          onChange={onAmountChange}
          variant="standard"
          onBlur={onBlur}
          placeholder="Enter amount"
          error={amountError}
          helperText={
            amountError
              ? `Please deposit at least ${minimumAmount}ETH. The minimum is calculated based on your current NFT commitments.`
              : ""
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">ETH</InputAdornment>,
          }}
        />
        <AddFundsButton
          provider="metamask"
          onClick={handleMetaMaskClick}
          disabled={!isMetaMaskInstalled() || amountError}
        />
        <AddFundsButton
          provider="wyre"
          onClick={handleWyreClick}
          disabled={amountError}
        />
      </Stack>
    </BottomDrawer>
  );
};

export default Deposit;
