import { useEffect, useState } from "react";
import { BigNumber } from "bignumber.js";
import Swal from "sweetalert2";
import { Stack, Typography } from "@mui/material";
import { createBuyoutRequest } from "./utils";
import { ethWeb3 } from "magic";
import {
  calcMissingFundsForActionWei,
  METHOD_GAS_LIMIT,
  runWithRetries,
  createContractInstance,
  MULTEEZ_FEE_PRCT,
} from "utils";
import AddGasModal from "components/AddGasModal";
import Page from "components/Page";
import Button from "components/Button";
import Loading from "components/Loading";
import { toFixed } from "utils/common";
import { logError, logInfo } from "utils/logger";
import useUserData from "hooks/useUserData";
import useCheckIfTransactionSent, {
  TransactionOriginAction,
} from "hooks/useCheckIfTransactionSent";
import useProposalData from "hooks/useProposalData";
import useCurrentGasPrice from "hooks/useCurrentGasPrice";
import Disclaimer from "components/Disclaimer";
import NftInfoCard from "components/NftInfoCard";
import TransactionRecap from "components/TransactionRecap";

export default function RequestBuyOut() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const { data: userData } = useUserData();
  const { data: proposalData } = useProposalData(
    urlParams.get("proposalId") || ""
  );
  const gasUnitPrice = useCurrentGasPrice();

  const [disableButton, setDisableButton] = useState(false);
  const [showAddGasModal, setShowAddGasModal] = useState(false);
  const [gasNeeded, setGasNeeded] = useState("0");
  const [finalSellerAmount, setFinalSellerAmount] = useState("0");
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [totalUserOffer, setTotalUserOffer] = useState(0);

  useCheckIfTransactionSent({
    user: userData,
    proposal: proposalData,
    originAction: TransactionOriginAction.RequestBuyout,
  });

  useEffect(() => {
    if (!proposalData) {
      return;
    }

    setDisableButton(true);

    const proposalVal = new BigNumber(proposalData?.proposedValue);
    const userRevenuePercent = new BigNumber(1).minus(MULTEEZ_FEE_PRCT);
    const finalSellerAmountEth = proposalVal
      .multipliedBy(userRevenuePercent)
      .multipliedBy(proposalData.proposedSeats);
    setFinalSellerAmount(finalSellerAmountEth.toFixed(5));
    setTotalUserOffer(
      new BigNumber(proposalData.proposedValue)
        .multipliedBy(proposalData.proposedSeats)
        .toNumber()
    );
    setDisableButton(false);
  }, [proposalData]);

  const shouldDisable = () => !proposalData || !userData;

  const handleConfirm = async () => {
    if (!proposalData || !userData) {
      return;
    }

    try {
      setIsActionInProgress(true);
      setDisableButton(true);
      await logInfo("Proposal data:");
      await logInfo(proposalData);
      const daoAddress = proposalData?.daoAddress;
      const totalSellerShareOffered =
        Number(proposalData?.totalSellerShareOffered) || 0;

      const buyoutPriceEther = new BigNumber(proposalData?.proposedValue)
        .multipliedBy(proposalData?.proposedSeats)
        .toString();

      const DaoDecimals = 1000;
      const buyoutPercent = new BigNumber(totalSellerShareOffered)
        .multipliedBy(DaoDecimals)
        .toNumber();

      const buyoutPrice = ethWeb3.utils.toWei(String(buyoutPriceEther));
      const buyoutPriceBN = new BigNumber(buyoutPrice);

      const accounts = await ethWeb3.eth.getAccounts();
      const userAddress = accounts[0];

      const howMuchGasNeeded = await calcMissingFundsForActionWei({
        gasUnitPrice,
        user: userData,
        gasUnits: METHOD_GAS_LIMIT,
      });

      if (!howMuchGasNeeded.isEqualTo("0")) {
        setGasNeeded(howMuchGasNeeded.toString());
        setShowAddGasModal(true);
        return;
      }

      const dao = await runWithRetries(createContractInstance, daoAddress);

      if (!dao) {
        await logInfo(
          "DAO instance failed to be created please try again later, no money was spent"
        );
        alert(
          "DAO failed to be created please try again later, no money was spent"
        );
        return "";
      }

      const buyerMail = urlParams.get("buyerMail");
      const buyerUserId = `USER_${buyerMail}`;
      logInfo(
        `creating buyout request with ${buyoutPrice} price and ${buyoutPercent} percent`
      );
      await createBuyoutRequest({
        userAddress,
        buyoutPrice: buyoutPriceBN.toString(),
        buyoutPercent,
        dao,
        gasUnitPrice,
        proposal: proposalData,
        userId: userData?.id || "",
        buyerUserId,
        onComplete: () => setIsActionInProgress(false),
      });
    } catch (e) {
      Swal.fire({
        title: "Failed to create Buyout request",
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
      console.error(String(e));
      await logError("general error with error:");
      await logError(String(e));
    }
  };

  return (
    <Page>
      {(!userData || isActionInProgress) && (
        <Loading
          text={`${
            !userData ? "Loading" : "Action in progress, please wait..."
          }`}
        />
      )}
      {showAddGasModal && (
        <AddGasModal
          open={showAddGasModal}
          onClose={() => setShowAddGasModal(false)}
          gasPriceInWei={gasNeeded.toString()}
          onFundsAdded={confirm}
          userEmail={userData?.email || ""}
        />
      )}
      <Stack>
        <Typography variant="h5" fontWeight={900} my={4}>
          Sale confirmation
        </Typography>
        <NftInfoCard
          imageUrl={proposalData?.imageUrl}
          name={proposalData?.nftName}
          owners={0}
          type="requestbuyout"
          proposedSeats={proposalData?.proposedSeats}
          proposedValue={totalUserOffer}
          headerInfo={[
            ["Price per seat", `${proposalData?.proposedValue} ETH`],
            ["My ownership", `${proposalData?.totalSellerSeats} seats`],
          ]}
        />
        <Typography variant="subtitle2" fontWeight={900} mt={6} mb={2}>
          Transaction Recap
        </Typography>
        <TransactionRecap
          data={[
            ["Your expected revenue", `${finalSellerAmount} ETH`],
            [
              "Estimated fees*",
              `${toFixed(
                new BigNumber(
                  ethWeb3.utils.fromWei(gasUnitPrice.toString())
                ).multipliedBy(String(METHOD_GAS_LIMIT))
              )} ETH`,
            ],
            ["Multeez service fee", "Free"],
          ]}
        />
        <Button
          disabled={disableButton || shouldDisable()}
          onClick={handleConfirm}
        >
          <Typography variant="h6" fontWeight={900} component="span">
            Confirm
          </Typography>
        </Button>
        <Disclaimer />
      </Stack>
    </Page>
  );
}
